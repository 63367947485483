import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content_app"
}
const _hoisted_2 = { class: "main-content d-flex flex-column transition overflow-hidden" }
const _hoisted_3 = {
  key: 1,
  class: "main-content login"
}
const _hoisted_4 = { class: "container d-flex flex-column overflow-hidden transition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_ctx.isAuthenticated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MainHeader),
        _createVNode(_component_MainSidebar),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view),
          _createVNode(_component_MainFooter)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ]))
}