// socketService.ts
import { io, Socket } from 'socket.io-client';

class SocketService {
  private socket: Socket;

  constructor() {
    this.socket = io(process.env.VUE_APP_CMC_URL, {
      path: '/api/cmc/24/yetox/v1/real_time', 
      forceNew: true, 
      reconnectionAttempts: 3,
    });

    // Manejo de conexión
    this.socket.on('connect', () => {
      console.log('Conectado a Socket.IO');
    });

    // Manejo de errores de conexión
    this.socket.on('connect_error', (error) => {
      console.error('Error de conexión:', error);
    });

    // Manejo de desconexión
    this.socket.on('disconnect', () => {
      console.log('Desconectado de Socket.IO');
    });
  }

  // Conectar al servidor
  public connect() {
    this.socket.connect();
  }

  // Desconectar del servidor
  public disconnect() {
    this.socket.disconnect();
  }

  // Escuchar un evento específico
  public on(event: string, callback: (data: any) => void) {
    this.socket.on(event, callback);
  }

  // Emitir un evento
  public emit(event: string, data: any) {
    this.socket.emit(event, data);
  }
}

// Exportar una instancia del servicio
const socketService = new SocketService();
export default socketService;
