<template>
  <div class="dashboard-container" :class="{ 'sidebar-hidden': !isSidebarVisible }">
    <!-- Barra lateral con pestañas para filtros -->
    <aside v-if="isSidebarVisible" class="sidebar">
      <h2>Filtros</h2>

      <div class="search-bar">
        <input type="text" v-model="searchQuery" placeholder="Buscar vehículo..." />
      </div>

      <div class="tabs">
        <button class="tab-button" :class="{ active: selectedTab === 'all' }" @click="setTab('all')">
          Todos
        </button>
        <button class="tab-button" :class="{ active: selectedTab === 'NAENS' }" @click="setTab('NAENS')">
          NAENS
        </button>
        <button class="tab-button" :class="{ active: selectedTab === 'SILM' }" @click="setTab('SILM')">
          SILM
        </button>
      </div>

      <ul class="vehicle-list">
        <li v-for="vehicle in filteredVehicles" :key="vehicle.imei" @click="focusOnVehicle(vehicle)"
          class="vehicle-item">
          <strong>Fecha:</strong> {{ vehicle.fecha }} - <strong>Unidad:</strong> {{ vehicle.unidad }}
          <br />
          <strong>Posición:</strong> {{ vehicle.latitud.toFixed(5) }}, {{ vehicle.longitud.toFixed(5) }}
          <br />
          <strong>Ubicación: :</strong> {{ vehicle.ubicacion }}
          <br />
          <strong>imei:</strong> {{ vehicle.imei }}
          <br />
          <strong>Velocidad: </strong> {{ vehicle.velocidad }}
          <br />
          <strong>Placas: </strong> {{ vehicle.placas }}
          <br />
          <strong>Angulo: </strong> {{ vehicle.Angulo }}
          <br />
          <strong>ignicion: </strong> {{ vehicle.ignicion }}
          <br />
          <strong>odometro: </strong> {{ vehicle.odometro }}
        </li>
      </ul>
    </aside>

    <div class="map-container">
      <div id="map" ref="mapRef"></div>

      <div class="map-buttons">
        <button class="icon-btn" @click="zoomIn">
          <img src="../../assets/plus-small_4338829.png" title="Zoom In" alt="Zoom In" />
        </button>
        <button class="icon-btn" @click="zoomOut">
          <img src="../../assets/minus-small_4338830.png" title="Zoom Out" alt="Zoom Out" />
        </button>
        <button class="icon-btn" @click="resetView">
          <img src="../../assets/icons8-cita-recurrente-50.png" title="Restablecer Vista" alt="Restablecer Vista" />
        </button>
        <button class="icon-btn" @click="toggleMapType">
          <img title="Cambiar vista" src="../../assets/movilidad.png" alt="Cambiar Vista" />
        </button>
        <button class="icon-btn" @click="toggleTraffic">
          <img src="../../assets/embotellamiento.png" title="Mostrar tráfico" alt="Mostrar/Ocultar Tráfico" />
        </button>
        <button class="icon-btn" @click="centerOnUserLocation">
          <img src="../../assets/mapa.png" title="Mi Ubicación" alt="Mi Ubicación" />
        </button>
        <button class="icon-btn" @click="toggleFullscreen">
          <img src="../../assets/pantalla-completa.png" title="Pantalla completa" alt="Pantalla Completa" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from "vue";
import socketService from '@/services/socketService';
import { vehiclesData } from "@/interfaces/interfaces";
import carIcon from '@/assets/car.png';


export default {
  setup() {
    const map = ref<google.maps.Map | null>(null);
    const mapCenter: google.maps.LatLngLiteral = { lat: 19.4326, lng: -99.1332 };
    const selectedTab = ref<string>("all");
    const isSidebarVisible = ref<boolean>(true);
    const searchQuery = ref<string>("");
    const filteredVehicles = ref<vehiclesData[]>([]);
    const buscad = ref<HTMLElement | null>(null);
    const vehicles = ref<vehiclesData[]>([]);
    const trafficLayer = ref<google.maps.TrafficLayer | null>(null);

    const filterVehicles = () => {
      let filtered = vehicles.value;

      if (selectedTab.value !== "all") {
        filtered = filtered.filter(vehicle => vehicle.unidad.startsWith(selectedTab.value)); // Cambio aquí
      }

      if (searchQuery.value) {
        filtered = filtered.filter(vehicle => vehicle.unidad.toLowerCase().includes(searchQuery.value.toLowerCase()));
      }

      filteredVehicles.value = filtered;
    };

    watch([selectedTab, searchQuery], filterVehicles, { immediate: true });

    onMounted(() => {
      map.value = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: mapCenter,
        zoom: 13,
        mapTypeId: "hybrid",
        mapId: "f8d56006232c1d87",
        disableDefaultUI: true,
      });

      socketService.connect();

      socketService.on('gps-realtime', (receivedData: vehiclesData[]) => {

        console.log(receivedData);
        receivedData.forEach((vehicleData) => {
          const existingVehicle = vehicles.value.find(vehicle => vehicle.imei === vehicleData.imei);

          if (!existingVehicle) {
            vehicles.value.push(vehicleData);

            const marker = new google.maps.Marker({
              position: { lat: vehicleData.latitud, lng: vehicleData.longitud },
              map: map.value,
              icon: {
                url: carIcon,
                scaledSize: new google.maps.Size(50, 50),
              },
              title: `Vehículo ${vehicleData.unidad}`,
            });

            (vehicleData as any).marker = marker;
          } else {
            // Si ya existe, actualizar su posición y otros datos
            existingVehicle.latitud = vehicleData.latitud;
            existingVehicle.longitud = vehicleData.longitud;
            existingVehicle.velocidad = vehicleData.velocidad;
            existingVehicle.ignicion = vehicleData.ignicion;
            existingVehicle.Angulo = vehicleData.Angulo;

            // Actualizar la posición del marcador
            existingVehicle.marker?.setPosition({ lat: vehicleData.latitud, lng: vehicleData.longitud });

            // Actualizar la rotación del marcador
            if (existingVehicle.marker) {
              existingVehicle.marker.setIcon({
                url: carIcon,
                scaledSize: new google.maps.Size(50, 50),
                rotation: vehicleData.Angulo,
              });
            }
          }
        });
      });

      setInterval(moveVehicles, 1000);
    });

    const moveVehicles = () => {
      vehicles.value.forEach((vehicle) => {
        if (vehicle.velocidad > 0) {
          vehicle.latitud += (Math.random() - 0.5) * 0.001;
          vehicle.longitud += (Math.random() - 0.5) * 0.001;
          (vehicle as any).marker?.setPosition({ lat: vehicle.latitud, lng: vehicle.longitud });
        }
      });
    };


    const focusOnVehicle = (vehicle: { latitud: number, longitud: number }) => {
      map.value?.setCenter({ lat: vehicle.latitud, lng: vehicle.longitud });
      map.value?.setZoom(15);
    };

    const setTab = (tab: string) => {
      selectedTab.value = tab;
      filterVehicles();  // Filtrar los vehículos después de cambiar la pestaña
    };


    const zoomIn = () => {
      if (map.value) {
        const currentZoom = map.value.getZoom();
        if (currentZoom !== undefined) {
          map.value.setZoom(currentZoom + 1);
        }
      }
    };

    const zoomOut = () => {
      if (map.value) {
        const currentZoom = map.value.getZoom();
        if (currentZoom !== undefined) {
          map.value.setZoom(currentZoom - 1);
        }
      }
    };

    const resetView = () => {
      if (map.value) {
        map.value.setCenter(mapCenter);
        map.value.setZoom(13);
      }
    };

    const toggleMapType = () => {
      if (map.value) {
        const currentType = map.value.getMapTypeId();
        map.value.setMapTypeId(currentType === "hybrid" ? "roadmap" : "hybrid");
      }
    };

    const toggleTraffic = () => {
      if (map.value) {
        if (!trafficLayer.value) {
          trafficLayer.value = new google.maps.TrafficLayer();
        }
        const isTrafficVisible = trafficLayer.value.getMap();
        trafficLayer.value.setMap(isTrafficVisible ? null : map.value);
      }
    };

    const centerOnUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos: google.maps.LatLngLiteral = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.value?.setCenter(pos);
          map.value?.setZoom(15);
        });
      }
    };

    const toggleFullscreen = () => {
      isSidebarVisible.value = !isSidebarVisible.value;
      // const elem = document.documentElement;
      // if (!document.fullscreenElement) {
      //   elem.requestFullscreen();
      // } else if (document.exitFullscreen) {
      //   document.exitFullscreen();
      // }
    };

    return {
      map,
      mapCenter,
      selectedTab,
      isSidebarVisible,
      vehicles,
      filteredVehicles,
      searchQuery,
      focusOnVehicle,
      setTab,
      zoomIn,
      zoomOut,
      resetView,
      toggleMapType,
      toggleTraffic,
      centerOnUserLocation,
      toggleFullscreen,
    };
  },
};
</script>


<style scoped>
.dashboard-container {
  display: flex;
  height: 100vh;
  background-color: #f4f4f4;
}

.sidebar {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
}

.sidebar h2 {
  margin-bottom: 20px;
  color: #007bff;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  flex: 1;
  padding: 10px;
  background-color: #e9ecef;
  border: none;
  border-radius: 5px;
}

.tab-button.active {
  background-color: #007bff;
  color: #fff;
}

.vehicle-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vehicle-item {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: aliceblue;
}

.map-container {
  flex-grow: 1;
}

#map {
  width: 100%;
  height: 100%;
}

.map-buttons {
  position: absolute;
  bottom: 20px;
  right: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon-btn {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-btn img {
  width: 24px;
  height: 24px;
}

.icon-btn:hover {
  background-color: rgba(255, 255, 255, 1);
}
</style>
