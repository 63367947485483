<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div
            class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
            <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />
            <div class="d-sm-flex align-items-center">
                <h5 class="text-center">Inventario</h5>
            </div>
            <div class="d-sm-flex align-items-center">
                <h6 class="text-center">
                    Fecha y hora de actualización:
                    <span class="text-primary">{{ updateDate }}</span>
                </h6>
            </div>

        </div>
        <div class="card-body p-15 p-sm-20 p-md-25">
            <div class="mb-3">
                <InputText v-model="searchTerm" placeholder="Buscar por SKU o nombre de producto" class="w-100" />
            </div>

            <div class="table-responsive">
                <DataTable :value="filteredInventory" showGridlines tableStyle="min-width: 50rem" paginator :rows="10"
                    :rowsPerPageOptions="[5, 10, 20, 50]">
                    <Column field="ccodigoproducto" header="Sku" style="width: 10%"></Column>
                    <Column sortable field="cnombreproducto" header="Nombre" style="width: 60%"></Column>
                    <Column sortable field="existencia" header="Cantidad" style="width: 20%"></Column>
                    <Column header="Existencia" style="width: 10%">
                        <template #body="slotProps">
                            <Button icon="pi pi-plus-circle" label="Ver"
                                @click="seeDetails(slotProps.data.ccodigoproducto)" severity="primary"
                                size="small"></Button>
                        </template>
                    </Column>
                    <template #footer>
                        Total {{ inventoryData ? inventoryData.length : 0 }} Productos.
                    </template>
                </DataTable>
            </div>
        </div>

        <Dialog header="Detalles del Producto" v-model:visible="displayDetailsDialog" :modal="true" :closable="true"
            :style="{ width: '50vw' }">
            <template v-if="selectedProductDetails">
                <div v-for="(detail, index) in selectedProductDetails" :key="index">
                    <p><strong>Almacén:</strong> {{ detail.CNOMBREALMACEN }}</p>
                    <p><strong>Existencia:</strong> {{ detail.existencia }}</p>
                    <hr />
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import { inventoryItem } from "@/interfaces/interfaces";
import Inventory from "@/services/inventoryService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from 'primevue/inputtext';

export default defineComponent({
    name: "PageInventory",
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText
    },
    setup() {
        const isLoading = ref(true);
        const toast = useToast();
        const inventoryData = ref<inventoryItem[]>([]);
        const inventoryDetails = ref<inventoryItem[]>([]);
        const displayDetailsDialog = ref(false);
        const selectedProductDetails = ref<inventoryItem[]>([]);
        const searchTerm = ref("");
        const updateDate = ref("");

        const getInventoryData = async () => {
            try {
                isLoading.value = true;
                const response = await new Inventory().listInventoryData();
                if (!response.error) {
                    updateDate.value = response.inventario[0].fecha;
                    const inventoryArray = response.inventario[0].data;

                    const filteredInventory = inventoryArray.filter(item => item.souvenir === 0);

                    const groupedInventory = filteredInventory.reduce((acc, currentItem) => {
                        if (acc[currentItem.ccodigoproducto]) {
                            acc[currentItem.ccodigoproducto].existencia += currentItem.existencia;
                        } else {
                            acc[currentItem.ccodigoproducto] = { ...currentItem };
                        }
                        return acc;
                    }, {});

                    // Asignar los productos filtrados y agrupados
                    inventoryData.value = Object.values(groupedInventory);
                    inventoryDetails.value = filteredInventory;
                }

            } catch (error) {
                toast.error("Ocurrió un error al obtener el inventario");
            } finally {
                isLoading.value = false;
            }
        };

        const seeDetails = (ccodigoproducto: string) => {
            const productDetails = inventoryDetails.value.filter(item => item.ccodigoproducto === ccodigoproducto);

            selectedProductDetails.value = productDetails;
            displayDetailsDialog.value = true;
        };


        const filteredInventory = computed(() => {

            if (!searchTerm.value) {
                return inventoryData.value;
            }

            // Filtramos por SKU o nombre del producto
            return inventoryData.value.filter(item =>
                item.ccodigoproducto.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                item.cnombreproducto.toLowerCase().includes(searchTerm.value.toLowerCase())
            );
        });

        onMounted(async () => {
            await getInventoryData();
        });

        return {
            inventoryData,
            isLoading,
            seeDetails,
            displayDetailsDialog,
            selectedProductDetails,
            searchTerm,
            filteredInventory,
            updateDate
        };
    },
});
</script>
