<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Actualizar información</h5>
      <div class="row">
        <div class="col-md-12 pt-10">
          <div class="input-group">
            <span class="input-group-text">Nombre establecimiento</span>
            <input
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
              v-model="form.nombre_establecimiento"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedAgente"
            :options="agentes"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione un agente"
            track-by="uuid"
            label="nombre_agente_cq"
            @select="updateSelectedAgente"
            @remove="removeSelectedAgente"
          ></multiselect>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Razón Social</span>
            <input
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
              v-model="form.razon_social"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Correo electrónico</span>
            <input
              v-model="form.correo_electronico"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Teléfono</span>
            <input
              v-model="form.telefono"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">RFC</span>
            <input
              v-model="form.rfc"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Giro comercial</span>
            <input
              v-model="form.giro_comercial"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Nombre de contacto</span>
            <input
              v-model="form.contacto_nombre"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Teléfono de contacto</span>
            <input
              v-model="form.contacto_telefono"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Calle 1</span>
            <input
              v-model="form.calle1"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Calle 2</span>
            <input
              v-model="form.calle2"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Número exterior</span>
            <input
              v-model="form.numero_ext"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Número interior</span>
            <input
              v-model="form.numero_int"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Colonia</span>
            <input
              v-model="form.colonia"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Código Postal</span>
            <input
              v-model="form.cp"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Estado</span>
            <input
              v-model="form.estado"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Municipio</span>
            <input
              v-model="form.municipio"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <!-- Multiselect para Nivel Cliente -->
        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedNivelCliente"
            :options="nivelesCliente"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione el nivel de cliente"
            track-by="uuid"
            label="descripcion"
            @select="updateSelectedNivelCliente"
            @remove="removeSelectedNivelCliente"
          ></multiselect>
        </div>

        <!-- Multiselect para Clasificación Cliente -->
        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedClasificacionCliente"
            :options="clasificacionesCliente"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione la clasificación de cliente"
            track-by="uuid"
            label="nombre"
            @select="updateSelectedClasificacionCliente"
            @remove="removeSelectedClasificacionCliente"
          ></multiselect>
        </div>

        <!-- Multiselect para Forma de Pago -->
        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedFormaPago"
            :options="formasPago"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione la forma de pago"
            track-by="uuid"
            label="nombre"
            @select="updateSelectedFormaPago"
            @remove="removeSelectedFormaPago"
          ></multiselect>
        </div>

        <!-- Multiselect para Frecuencia de Compra -->
        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedFrecuenciaCompra"
            :options="frecuenciasCompra"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione la frecuencia de compra"
            track-by="uuid"
            label="descripcion"
            @select="updateSelectedFrecuenciaCompra"
            @remove="removeSelectedFrecuenciaCompra"
          ></multiselect>
        </div>

        <div class="col-sm-12 col-md-6 pt-4 row justify-content-center">
          <button
            @click="botonenviar"
            type="button"
            class="btn btn-primary PT-4 text-center"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import control from "@/services/apiService";
import Multiselect from "vue-multiselect";

export default defineComponent({
  name: "FormularioMaps",
  components: { Multiselect },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const form = ref({
      nombre_establecimiento: "",
      vendedor: "",
      razon_social: "",
      telefono: "",
      correo_electronico: "",
      rfc: "",
      giro_comercial: "",
      contacto_nombre: "",
      contacto_telefono: "",
      longitud: "",
      latitud: "",
      calle1: "",
      calle2: "",
      numero_ext: "",
      numero_int: "",
      colonia: "",
      uniclave:"",
      cp: "",
      estado: "",
      municipio: "",
      uuid_vendedor: "",
      uuid_nivel_cliente: "",
      uuid_clasificacion_cliente: "",
      uuid_forma_pago: "",
      uuid_estado: "",
      uuid_frecuencia_compra: ""
    });

    const frecuenciasCompra = ref<Array<{ uuid: string; descripcion: string }>>([]);
    const selectedFrecuenciaCompra = ref<{ uuid: string; descripcion: string } | null>(null);
    const nivelesCliente = ref<Array<{ uuid: string; descripcion: string }>>([]);
    const selectedNivelCliente = ref<{ uuid: string; descripcion: string } | null>(null);
    const selectedAgente = ref<{ uuid: string; nombre: string } | null>(null);
    const clasificacionesCliente = ref<Array<{ uuid: string; nombre: string }>>([]);
    const agentes = ref<Array<{ uuid: string; nombre: string }>>([]);
    const selectedClasificacionCliente = ref<{ uuid: string; nombre: string } | null>(null);
    const formasPago = ref<Array<{ uuid: string; nombre: string }>>([]);
    const selectedFormaPago = ref<{ uuid: string; nombre: string } | null>(null);
    const states = ref<Array<{ uuid: string; nombre: string }>>([]);
    const geocoder = ref<google.maps.Geocoder | null>(null);

    const isLoading = ref(false);
    const toast = useToast();

    onMounted(async() => {
      await getNivelesCliente();
      await getAgente();
      await getClasificacionesCliente();
      await getFormasPago();
      await getFrecuenciasCompra();
      if (!geocoder.value) {
        geocoder.value = new google.maps.Geocoder(); 
      }

});

    const getNivelesCliente = async () => {
      try {
        const response = await new control().ListLevelClient();
        nivelesCliente.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar los niveles de cliente.");
      }
    };
    

        const getStateList = async () => {
      try {
        const response = await new control().stateList();
        states.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los estados:", error);
        toast.error("Error al cargar la lista de estados");
      }
    };

    const getStateFromCoordinates = async (lat: number, lng: number) => {
      if (!geocoder.value) {
        geocoder.value = new google.maps.Geocoder();
      }

      geocoder.value.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results && results[0]) { 
          const addressComponents = results[0].address_components;
          const stateComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          if (stateComponent) {
            const stateName = stateComponent.long_name;
            form.value.estado = stateName; 

            const foundState = states.value.find(
              (state) => state.nombre === stateName
            );

            if (foundState) {
              form.value.uuid_estado = foundState.uuid;
            } 
          }
        } else {
          toast.error("No se pudo obtener la información del estado.");
        }
      });
    };

    watch(
      () => [form.value.latitud, form.value.longitud],
      async ([newLat, newLong]) => {
        if (newLat && newLong) {
          const lat = typeof newLat === 'string' ? parseFloat(newLat) : newLat;
          const lng = typeof newLong === 'string' ? parseFloat(newLong) : newLong;

          if (!isNaN(lat) && !isNaN(lng)) {
            await getStateFromCoordinates(lat, lng);
          }
        }
      },
      { immediate: true }
    );


    watch(
      () => [form.value.latitud, form.value.longitud],
      async ([newLat, newLong]) => {
        if (newLat && newLong) {
          await getStateList(); 
          const foundState = states.value.find(
            (state) => state.nombre === form.value.estado
          );
          form.value.uuid_estado = foundState ? foundState.uuid : "";
        }
      },
      { immediate: true }
    );

    watch(
  () => [clasificacionesCliente.value, formasPago.value, frecuenciasCompra.value, nivelesCliente.value, agentes.value],
  ([newClasificaciones, newFormasPago, newFrecuencias, newNiveles, newAgentes]) => {
    
    if (newClasificaciones.length > 0 && props.formData.uuid_clasificacion_cliente) {
      const clasificacionesTyped = newClasificaciones as Array<{ uuid: string; nombre: string }>;
      selectedClasificacionCliente.value = clasificacionesTyped.find(
        (clasificacion) => clasificacion.uuid === props.formData.uuid_clasificacion_cliente
      ) || null;
    }

    if (newFormasPago.length > 0 && props.formData.uuid_forma_pago) {
      const formasPagoTyped = newFormasPago as Array<{ uuid: string; nombre: string }>;
      selectedFormaPago.value = formasPagoTyped.find(
        (pago) => pago.uuid === props.formData.uuid_forma_pago
      ) || null;
    }

    if (newFrecuencias.length > 0 && props.formData.uuid_frecuencia_compra) {
      const frecuenciasTyped = newFrecuencias as Array<{ uuid: string; descripcion: string }>;
      selectedFrecuenciaCompra.value = frecuenciasTyped.find(
        (frecuencia) => frecuencia.uuid === props.formData.uuid_frecuencia_compra
      ) || null;
    }

    if (newNiveles.length > 0 && props.formData.uuid_nivel_cliente) {
      const nivelesTyped = newNiveles as Array<{ uuid: string; descripcion: string }>;
      selectedNivelCliente.value = nivelesTyped.find(
        (nivel) => nivel.uuid === props.formData.uuid_nivel_cliente
      ) || null;
    }

    if (newAgentes.length > 0 && props.formData.uuid_vendedor) {
      const agentesTyped = newAgentes as Array<{ uuid: string; nombre: string }>;
        selectedAgente.value = agentesTyped.find(
        (agente) => agente.uuid === props.formData.uuid_vendedor
      ) || null;
    }
  },
  { immediate: true }
);




const getAgente = async () => {
      try {
        const response = await new control().agentList();
        agentes.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las clasificaciones de cliente.");
      }
    };

    const getClasificacionesCliente = async () => {
      try {
        const response = await new control().ListClasifiClient();
        clasificacionesCliente.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las clasificaciones de cliente.");
      }
    };

    const getFormasPago = async () => {
      try {
        const response = await new control().formaPagos();
        formasPago.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las formas de pago.");
      }
    };

    const getFrecuenciasCompra = async () => {
      try {
        const response = await new control().frecuenciaCompra();
        frecuenciasCompra.value = response.datos;
      } catch (error) {
        toast.error("Error al cargar las frecuencias de compra.");
      }
    };

    watch(
      () => props.formData,
      (newFormData) => {
        form.value = {
          ...form.value,
          ...newFormData
        };
      },
      { deep: true }
    );

    const updateSelectedAgente = (selected) => {
      form.value.uuid_vendedor = selected ? selected.uuid : "";
    };

    const removeSelectedAgente = () => {
      form.value.uuid_vendedor = "";
      selectedAgente.value = null;
    };


    const updateSelectedNivelCliente = (selected) => {
      form.value.uuid_nivel_cliente = selected ? selected.uuid : "";
    };

    const removeSelectedNivelCliente = () => {
      form.value.uuid_nivel_cliente = "";
      selectedNivelCliente.value = null;
    };

    const updateSelectedClasificacionCliente = (selected) => {
      form.value.uuid_clasificacion_cliente = selected ? selected.uuid : "";
    };

    const removeSelectedClasificacionCliente = () => {
      form.value.uuid_clasificacion_cliente = "";
      selectedClasificacionCliente.value = null;
    };

    const updateSelectedFormaPago = (selected) => {
      form.value.uuid_forma_pago = selected ? selected.uuid : "";
    };

    const removeSelectedFormaPago = () => {
      form.value.uuid_forma_pago = "";
      selectedFormaPago.value = null;
    };

    const updateSelectedFrecuenciaCompra = (selected) => {
      form.value.uuid_frecuencia_compra = selected ? selected.uuid : "";
    };

    const removeSelectedFrecuenciaCompra = () => {
      form.value.uuid_frecuencia_compra = "";
      selectedFrecuenciaCompra.value = null;
    };

    const botonenviar = async () => {
  try {
    isLoading.value = true;

    const payload = {
      ...form.value
    };


    const response = await new control().updateClient(payload);

    if (!response.error) {
      toast.success("Información actualizada correctamente");
    } else {
      toast.error(`Error al actualizar la información`);
    }
  } catch (error) {
    toast.error(`Ocurrió un error al enviar el formulario`);
  } finally {
    isLoading.value = false;
  }
};



    return {
      form,
      botonenviar,
      nivelesCliente,
      agentes,
      clasificacionesCliente,
      formasPago,
      frecuenciasCompra,
      selectedAgente,
      selectedNivelCliente,
      selectedClasificacionCliente,
      selectedFormaPago,
      selectedFrecuenciaCompra,
      updateSelectedAgente,
      removeSelectedAgente,
      updateSelectedNivelCliente,
      updateSelectedClasificacionCliente,
      updateSelectedFormaPago,
      updateSelectedFrecuenciaCompra,
      removeSelectedNivelCliente,
      removeSelectedClasificacionCliente,
      removeSelectedFormaPago,
      removeSelectedFrecuenciaCompra,
      isLoading
    };
  }
});
</script>
