<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Registrar Clientes</h5>
      <form class="row">
        <div class="col-md-12 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Nombre establecimiento</span>
            <input
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
              v-model="form.nombre_establecimiento"
            />
            <div class="invalid-feedback">
              Por favor, ingrese el nombre del establecimiento.
            </div>
          </div>
        </div>
        <div class="col-md-6 pt-10 rounded-md">
          <multiselect
            v-model="selectedAgent"
            :options="agentes"
            :searchable="true"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Selecciona un agente"
            track-by="uuid"
            label="nombre_agente_cq"
            @select="updateSelectedAgent"
            @remove="removeSelectedAgent"
          >
            <template #singleLabel="{ option }">
              <span>{{ option.nombre_agente_cq }}</span>
            </template>
          </multiselect>
        </div>
        <div class="col-md-6 pt-10 rounded-md">
          <multiselect
            v-model="selectedFrecuen"
            :options="frecuencias"
            :searchable="true"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Selecciona una frecuencia de compra"
            track-by="uuid"
            label="descripcion"
            @select="updateSelectedFrecuencia"
            @remove="removeSelectedFrecuencia"
          >
            <template #singleLabel="{ option }">
              <span>{{ option.descripcion }}</span>
            </template>
          </multiselect>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Razón Social</span>
            <input
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
              v-model="form.razon_social"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Correo electrónico</span>
            <input
              v-model="form.correo_electronico"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Telefono</span>
            <input
              v-model="form.telefono"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">RFC</span>
            <input
              v-model="form.rfc"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Giro comercial</span>
            <input
              v-model="form.giro_comercial"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Nombre de contacto</span>
            <input
              v-model="form.contacto_nombre"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Teléfono de contacto</span>
            <input
              v-model="form.telefono_contacto"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Calle 1</span>
            <input
              v-model="form.calle1"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Calle 2</span>
            <input
              v-model="form.calle2"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Número exterior</span>
            <input
              v-model="form.numero_ext"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Número interior</span>
            <input
              v-model="form.numero_int"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Colonia</span>
            <input
              v-model="form.colonia"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>

        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Código Postal</span>
            <input
              v-model="form.cp"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellene este campo.</div>
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Tipo de cliente</span>
            <select
              v-model="form.uuid_clasificacion_cliente"
              class="form-select shadow-none fs-md-15 text-black"
            >
              <option value="" disabled>Seleccione un tipo de cliente</option>
              <option
                v-for="tipo in tiposCliente"
                :key="tipo.uuid"
                :value="tipo.uuid"
              >
                {{ tipo.nombre }}
              </option>
            </select>
            <div class="invalid-feedback">
              Por favor, seleccione un tipo de cliente.
            </div>
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Forma de pago</span>
            <select
              v-model="form.uuid_forma_pago"
              class="form-select shadow-none fs-md-15 text-black"
            >
              <option value="" disabled>Selecciona una forma de pago</option>
              <option
                v-for="pago in pagos"
                :key="pago.uuid"
                :value="pago.uuid"
              >
                {{ pago.nombre }}
              </option>
            </select>
            <div class="invalid-feedback">
              Por favor, seleccione un tipo de cliente.
            </div>
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Nivel de cliente</span>
            <select
              v-model="form.uuid_nivel_cliente"
              class="form-select shadow-none fs-md-15 text-black"
            >
              <option value="" disabled>Seleccione un nivel del cliente</option>
              <option
                v-for="nivelCliente in nivelesCliente"
                :key="nivelCliente.uuid"
                :value="nivelCliente.uuid"
              >
                {{ nivelCliente.descripcion }}
              </option>
            </select>
            <div class="invalid-feedback">
              Por favor, seleccione el nivel del cliente.
            </div>
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Estado</span>
            <input
              v-model="form.estado"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Porfavor, rellena este campo</div>
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <div class="input-group has-validation">
            <span class="input-group-text">Municipio</span>
            <input
              v-model="form.municipio"
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
            />
            <div class="invalid-feedback">Por favor, rellena este campo.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 pt-4 row justify-content-center">
          <button
            type="button"
            @click="submitForm()"
            class="btn btn-primary text-center"
            :disabled="isSubmitting"
          >

            Añadir
          </button>
        </div>
      </form>
    </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import control from "@/services/apiService";
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";

export default defineComponent({
  name: "FormMapsUpdate",
  components: {
    Multiselect,
  },
  props: {
    locationData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const form = ref({
      nombre_establecimiento: "",
      vendedor: "",
      razon_social: "",
      correo_electronico: "",
      telefono: "",
      rfc: "",
      giro_comercial: "",
      contacto_nombre: "",
      telefono_contacto: "",
      longitud: "",
      latitud: "",
      calle1: "",
      calle2: "",
      numero_ext: "",
      numero_int: "",
      colonia: "",
      cp: "",
      estado: "",
      municipio: "",
      uuid_estado: "",
      uuid_clasificacion_cliente: "",
      uuid_nivel_cliente: "",
      uuid_forma_pago: "",
      uuid_frecuencia_compra: "",
      uuid_vendedor: "",
    });

    const isSubmitting = ref(false);
    const tiposCliente = ref([]);
    const nivelesCliente = ref([]);
    const agentes = ref([]);
    const frecuencias = ref([]);
    const states = ref([]);
    const pagos = ref([]);
    const toast = useToast();
    const selectedAgent = ref(null);
    const selectedFrecuen = ref(null);

    const getStateList = async () => {
      try {
        const response = await new control().stateList();
        states.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los estados:", error);
        toast.error("Error al cargar la lista de estados");
      }
    };

    const getTipoCliente = async () => {
      try {
        const response = await new control().ListClasifiClient();
        tiposCliente.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los tipos de cliente:", error);
        toast.error("Error al cargar los tipos de cliente");
      }
    };

    const getLevelCliente = async () => {
      try {
        const response = await new control().ListLevelClient();
        nivelesCliente.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los niveles de cliente:", error);
        toast.error("Error al cargar los niveles de cliente");
      }
    };

    const getAgentList = async () => {
      try {
        const response = await new control().agentList();
        agentes.value = response.datos;
      } catch (error) {
        console.error("Error al obtener la lista de agentes:", error);
        toast.error("Error al cargar la lista de agentes");
      }
    };

    const getFrecuenciaCompra = async () => {
      try {
        const response = await new control().frecuenciaCompra();
        frecuencias.value = response.datos;
      } catch (error) {
        console.error("Error al obtener la lista de frecuencias:", error);
        toast.error("Error al cargar la lista de frecuencias");
      }
    };

    const getFormaPago = async () =>{
      try{
        const response = await new control().formaPagos();
        pagos.value = response.datos;
      }catch(error){
        console.error("Error al obtener la lista de frecuencias:", error);
        toast.error("Error al cargar la forma de pagos");
      }
    }

    const updateSelectedAgent = (selected) => {
      if (selected) {
        form.value.vendedor = selected.nombre_agente_cq;
        form.value.uuid_vendedor = selected.uuid;
      } else {
        form.value.vendedor = "";
        form.value.uuid_vendedor = "";
      }
    };

    const updateSelectedFrecuencia = (selectede) => {
      if (selectede) {
        form.value.uuid_frecuencia_compra = selectede.uuid;
      } else {
        form.value.uuid_frecuencia_compra = "";
      }
    };

    const removeSelectedAgent = () => {
      form.value.vendedor = "";
      form.value.uuid_vendedor = "";
      selectedAgent.value = null;
    };

    const removeSelectedFrecuencia = () => {
      form.value.uuid_frecuencia_compra = "";
      selectedFrecuen.value = null;
    };

    const resetForm = () => {
      Object.keys(form.value).forEach((key) => {
        form.value[key] = "";
      });
      selectedAgent.value = null;
    };

    watch(
      () => props.locationData,
      (newValue) => {
        if (newValue && Object.keys(newValue).length > 0) {
          Object.keys(newValue).forEach((key) => {
            if (key in form.value && newValue[key] !== undefined) {
              form.value[key] = newValue[key];
            }
          });
        }
      },
      { deep: true }
    );

    watch(
      () => form.value.estado,
      (newState) => {
        const foundState = states.value.find(
          (state) => state.nombre === newState
        );
        form.value.uuid_estado = foundState ? foundState.uuid : "";
      }
    );

    onMounted(async () => {
      await getStateList();
      await getTipoCliente();
      await getLevelCliente();
      await getAgentList();
      await getFrecuenciaCompra();
      await getFormaPago();
    });

    const submitForm = async () => {
  if (isSubmitting.value) return;
  isSubmitting.value = true;
  try {
    const response = await new control().registerClient(form.value);
    if (!response.error) {
      toast.success("Cliente registrado correctamente");
      resetForm();
    } else {
      toast.warning("El registro no se ha realizado correctamente");
    }
  } catch (error) {
    toast.error("Error al registrar el cliente");
  } finally {
    isSubmitting.value = false;
  }
};

    return {
      form,
      submitForm,
      tiposCliente,
      pagos,
      nivelesCliente,
      agentes,
      isSubmitting,
      selectedAgent,
      selectedFrecuen,
      updateSelectedAgent,
      updateSelectedFrecuencia,
      removeSelectedAgent,
      removeSelectedFrecuencia,
      frecuencias,
    };
  },
});
</script>
