import { AES, enc } from "crypto-js";
import axios from "axios";
import login from "./loginService";
import LocalStorageValue from "./localStorageService";

export default class Control {
  async encryptBody(body: any) {
    try {
      const jsonString = JSON.stringify(body);
      const encrypted = AES.encrypt(
        jsonString,
        process.env.VUE_APP_CMC_API_SECRET
      ).toString();
      return encrypted;
    } catch (error) {
      console.error("Error al encriptar el body:", error);
    }
  }

  async decryptBody(encryptedText: string) {
    const decryptedBytes = AES.decrypt(
      encryptedText,
      process.env.VUE_APP_CMC_API_SECRET
    );
    const decryptedText = decryptedBytes.toString(enc.Utf8);
    return decryptedText;
  }

  /*  esta funcion la utilizo para enviar peticiones */
  async fetchData(url, additionalPayload = {}) {
    await new login().isTokenExpired();

    const useKey = await new LocalStorageValue().getUserData();
    const token = await new LocalStorageValue().getToken();

    if (!useKey || !token) {
      throw new Error("No se pudo obtener información del usuario");
    }

    const payload = {
      key: useKey,
      ...additionalPayload,
    };

    const body = await this.encryptBody(payload);
    const encryptedRequestBody = JSON.stringify({
      data: body,
    });

    const configuration = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.VUE_APP_CMC_URL + url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: encryptedRequestBody,
    };

    const responseencry = await axios.request(configuration);
    if (responseencry.status < 200 || responseencry.status >= 300) {
      throw new Error(
        `La solicitud no fue exitosa. status: ${responseencry.status}`
      );
    }

    const response = await this.decryptBody(responseencry.data);
    return JSON.parse(response);
  }
  catch(error) {
    console.log("error en la solicitud", error);
  }

  async requestPoints() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos"
    );
  }

  async agentList() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/lista-agentes"
    );
  }

  async listAgentPoints(uuids) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-agente",
      {
        uuidAgente: uuids,
      }
    );
  }

  async stateList() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/traer-estados"
    );
  }

  async listStatesPoints(uuids) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-estado",
      {
        estados: uuids,
      }
    );
  }

  async regionList() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/traer-regiones"
    );
  }

  async listRegionPoints(uuids) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/solicitar-puntos-region",
      {
        regiones: uuids,
      }
    );
  }

  async serchIntelligent(text) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/buscador-inteligente",
      {
        text: text,
      }
    );
  }
  async registerClient(data) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/registrar-cliente",
      data
    );
  }

  async ListClasifiClient() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-clasificacion-cliente"
    );
  }
  async ListLevelClient() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-nivel-cliente"
    );
  }

  async updateClient(data) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/actualizar-cliente",
      data
    );
  }

  async frecuenciaCompra() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-frecuencia-compra"
    );
  }

  async formaPagos() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-forma-pago"
    );
  }

  async infoClientes(uuid) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/info-cliente",
      { uuid } 
    );
  }

  async ListAlmacenes() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-almacenes",
    );
  }
  async ListRoles() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-roles",
    );
  }
  async ListPermisos() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-permisos",
    );
  }
  async registerUser(data) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/registrar-usuario",
      data
    );
  }
  async ListUsers() {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/listar-usuarios",
    );
  }

  async infoUser(uuid) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/info-usuario",
      uuid
    );
  }

  async updateUser(datosActualizados) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/actualizar-usuario",
      datosActualizados
    );
  }

  async deleteUser(data) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/desactivar-usuario",
      data
    );
  }

  async activeUser(data) {
    return await this.fetchData(
      "/cmc24/api/cmc_siystem24/yetox/v1/activar-usuario",
      data
    );
  }
}  
