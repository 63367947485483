import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/welcome/5057942.png'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white welcome-support-desk-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 pt-xl-50 pb-xl-50 ps-xl-40 ps-xl-40 letter-spacing" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-8 col-md-8" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = {
  class: "d-block fw-bold text-primary mb-5",
  id: "current-date"
}
const _hoisted_7 = { class: "fw-semibold mb-8" }
const _hoisted_8 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formattedDate), 1),
            _createElementVNode("h2", _hoisted_7, [
              _cache[0] || (_cache[0] = _createTextVNode(" Bienvenido, ")),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.name), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-black-emphasis fs-md-15 fs-lg-16" }, " Al sistema de Centro de Monitoreo Continuo (CMC) ", -1))
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-lg-4 col-md-4 text-center mt-10 mt-md-0" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "welcome-image"
          })
        ], -1))
      ])
    ])
  ]))
}