<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing p-4">
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h4>Agregar producto analogo</h4>
      <div class="row">
        <div class="col-md-6 pt-10">
          <div class="input-group">
            <span class="input-group-text">Nombre</span>
            <input
              type="text"
              class="form-control shadow-none fs-md-15 text-black"
              v-model="form.nombre"
            />
          </div>
        </div>
        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedMarca"
            :options="marcas"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione una marca"
            track-by="uuid"
            label="nombre"
            @select="updateSelectedMarca"
            @remove="removeSelectedMarca"
          ></multiselect>
        </div>

        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedClasificacion"
            :options="clasificaciones"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione una clasificacion"
            track-by="uuid"
            label="nombre"
            @select="updateSelectedClasificaciones"
            @remove="removeSelectedClasificaciones"
          ></multiselect>
        </div>
        <div class="col-md-6 pt-10">
          <multiselect
            v-model="selectedProducto"
            :options="productos"
            :searchable="true"
            :close-on-select="true"
            placeholder="Seleccione un producto original"
            track-by="uuid_producto"
            label="nombre_producto"
            @select="updateSelectedProducto"
            @remove="removeSelectedProducto"
          ></multiselect>
        </div>

        <div class="container mt-3 col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Caracteristica</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(caracteristica, index) in selectedCaracteristicas"
                :key="index"
              >
                <td>
                  <div class="">
                    <multiselect
                      v-model="form.caracteristicas[index].uuid"
                      :options="caracteristicas"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder="Seleccione una caracteristica"
                      track-by="uuid"
                      label="nombre"
                      @select="
                        (caracteristica) =>
                          updateSelectedCaracteristicas(caracteristica, index)
                      "
                    ></multiselect>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.caracteristicas[index].valor"
                    placeholder="Introduce un valor"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="addCaracteristica" class="btn btn-secondary">
            Agregar Característica
          </button>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 pt-4 row justify-content-center">
        <button class="btn btn-primary text-center" @click="SendForm">
          Añadir
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Producto from "@/services/productoService";
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "ComponentAnalogo",
  components: { Multiselect },
  setup() {
    const form = ref({
      nombre: "",
      uuid_marca: "",
      uuid_clasificacion: "",
      uuid_producto_original: "",
      caracteristicas: [],
    });

    const marcas = ref([]);
    const selectedMarca = ref(null);
    const clasificaciones = ref([]);
    const selectedClasificacion = ref(null);
    const productos = ref([]);
    const selectedProducto = ref(null);
    const caracteristicas = ref([]);
    const selectedCaracteristicas = ref([]);
    const toast = useToast();

    const getMarcas = async () => {
      try {
        const response = await new Producto().ListMarca();
        marcas.value = response.datos;
      } catch (error) {
        toast.error("No se obtienen las marcas correctamente");
      }
    };

    const updateSelectedMarca = (marca) => {
      form.value.uuid_marca = marca.uuid;
    };

    const removeSelectedMarca = () => {
      form.value.uuid_marca = "";
    };

    const getClasificaciones = async () => {
      try {
        const response = await new Producto().ListClasificacion();
        clasificaciones.value = response.datos;
      } catch (error) {
        console.log(error);
        toast.error("Error al traer las clasificaciones");
      }
    };

    const updateSelectedClasificaciones = (clasificacion) => {
      form.value.uuid_clasificacion = clasificacion.uuid;
    };

    const removeSelectedClasificaciones = () => {
      form.value.uuid_clasificacion = "";
    };

    const getProductos = async () => {
      try {
        const response = await new Producto().ListProductos();
        productos.value = response.datos.map((producto) => ({
          uuid_producto: producto.uuid_producto,
          nombre_producto: producto.nombre_producto,
        }));
      } catch (error) {
        toast.error("Error al traer los productos");
      }
    };

    const updateSelectedProducto = (producto) => {
      form.value.uuid_producto_original = producto.uuid_producto;
    };

    const removeSelectedProducto = () => {
      form.value.uuid_producto_original = "";
    };

    const getCaracterisiticas = async () => {
      try {
        const response = await new Producto().ListCaracteristicas();
        caracteristicas.value = response.datos;
      } catch (error) {
        toast.error("Error al traer las caracteristicas");
      }
    };

    const updateSelectedCaracteristicas = (caracteristica, index) => {
      if (!form.value.caracteristicas[index]) {
        form.value.caracteristicas[index] = { uuid: "", valor: "" };
      }
      form.value.caracteristicas[index].uuid = caracteristica.uuid;
    };

    const removeSelectedCaracteristicas = (index) => {
      form.value.caracteristicas.splice(index, 1);
      selectedCaracteristicas.value.splice(index, 1);
    };

    const addCaracteristica = () => {
      if (
        !form.value.caracteristicas.some((c) => c.uuid === "" && c.valor === "")
      ) {
        selectedCaracteristicas.value.push(null);
        form.value.caracteristicas.push({ uuid: "", valor: "" });
      }
      console.log("Característica añadida:", form.value.caracteristicas);
    };

    const SendForm = async () => {
      try {
        const payload = {
          nombre: form.value.nombre,
          uuid_marca: form.value.uuid_marca,
          uuid_clasificacion: form.value.uuid_clasificacion,
          uuid_producto_original: form.value.uuid_producto_original,
          caracteristicas: form.value.caracteristicas
            .filter((c) => c.uuid && c.valor && c.valor.trim() !== "")
            .map((c) => ({
              uuid: c.uuid, // Solo el uuid, sin el nombre
              valor: c.valor,
            })),
        };

        // console.log(JSON.stringify(payload, null, 2));

        const response = await new Producto().SendForm(payload);

        if (response) {
          toast.success("Producto agregado exitosamente");
        } else {
          toast.error("Hubo un error al agregar el producto");
        }
      } catch (error) {
        toast.error("Error al enviar el formulario", error);
      }
    };

    onMounted(() => {
      getMarcas();
      getClasificaciones();
      getProductos();
      getCaracterisiticas();
    });

    return {
      form,
      SendForm,
      marcas,
      clasificaciones,
      selectedMarca,
      selectedClasificacion,
      getMarcas,
      updateSelectedClasificaciones,
      removeSelectedClasificaciones,
      getClasificaciones,
      updateSelectedMarca,
      removeSelectedMarca,
      selectedProducto,
      productos,
      getProductos,
      updateSelectedProducto,
      removeSelectedProducto,
      getCaracterisiticas,
      caracteristicas,
      updateSelectedCaracteristicas,
      removeSelectedCaracteristicas,
      selectedCaracteristicas,
      addCaracteristica,
    };
  },
});
</script>
