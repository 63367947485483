<template>
  <div>
    <VueLoading
      :active="isLoading"
      :is-full-page="true"
      loader="bars"
      :text="'Cargando...'"
    />
    <div ref="buscad" class="pac-card" id="pac-card">
      <div id="search-container">
        <input
          id="pac-input"
          type="text"
          placeholder="Buscar ubicación"
          class="pac-input"
          style="width: 100%; padding: 5px; margin-bottom: 5px"
        />
        <input
          id="maps-link-input"
          type="text"
          v-model="mapsLink"
          placeholder="Ingresa un enlace de Google Maps"
          @keyup.enter="handleMapsLink"
          style="width: 100%; padding: 5px"
        />
      </div>
    </div>
    <div id="map"></div>
    <div class="info-window" id="infowindow-content">
      <span id="place-name" class="title"></span><br />
      <span id="place-address"></span>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineEmits } from "vue";
import { useToast } from "vue-toastification";
const isLoading = ref(true);
const emit = defineEmits(["locationUpdated"]);

const geocoder = ref(null);
const locationData = ref({});
const map = ref(null);
const marker = ref(null);
const infowindowContent = ref(null);
const infowindow = ref(null);
const buscad = ref(null);
const mapsLink = ref("");
const toast = useToast();

const updateLocation = async (latLng, placeName = "") => {
  try {
    const response = await geocoder.value.geocode({ location: latLng });
    if (response.results[0]) {
      const result = response.results[0];
      locationData.value = {
        latitud: latLng.lat,
        longitud: latLng.lng,
        calle1:
          result.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        colonia:
          result.address_components.find((component) =>
            component.types.includes("sublocality_level_1")
          )?.long_name || "",
        ciudad:
          result.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        estado:
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "",
        cp:
          result.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "No disponible",
        municipio:
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_2")
          )?.long_name || "",
        nombre: placeName || "",
      };

      infowindowContent.value.querySelector("#place-name").textContent =locationData.value.nombre || "Ubicación seleccionada";
      infowindowContent.value.querySelector("#place-address").textContent =
        result.formatted_address;
      infowindow.value.open(map.value, marker.value);

      // Emitir el evento con la nueva ubicación
      emit("locationUpdated", locationData.value);
    }
  } catch (error) {
    console.error("Error al obtener la dirección:", error);
  }
};

const handleMapsLink = () => {
  const link = mapsLink.value;
  const regex = /3d(-?\d+\.\d+)!4d(-?\d+\.\d+)/;
  const match = link.match(regex);

  if (match) {
    const lat = parseFloat(match[1]);
    const lng = parseFloat(match[2]);
    const latLng = { lat, lng };

    map.value.setCenter(latLng);
    map.value.setZoom(17);
    marker.value.setPosition(latLng);
    marker.value.setVisible(true);

    updateLocation(latLng);
  } else {
    toast.error("No se pudo extraer la ubicación del enlace proporcionado.");
  }
};

onMounted(async () => {
  try {
    isLoading.value = true;

    const mapElement = document.getElementById("map");
    const input = document.getElementById("pac-input");
    infowindowContent.value = document.getElementById("infowindow-content");

    map.value = new google.maps.Map(mapElement, {
      center: { lat: 23.6345, lng: -102.5528 },
      zoom: 5,
      mapTypeControl: false,
    });

    geocoder.value = new google.maps.Geocoder();

    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };

    infowindow.value = new google.maps.InfoWindow();
    infowindow.value.setContent(infowindowContent.value);

    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.bindTo("bounds", map.value);

    marker.value = new google.maps.Marker({
      map: map.value,
      draggable: true,
      anchorPoint: new google.maps.Point(0, -29),
    });

    // Evento para el marcador arrastrable
    marker.value.addListener("dragend", () => {
      const position = marker.value.getPosition();
      if (position) {
        const latLng = { lat: position.lat(), lng: position.lng() };
        updateLocation(latLng);
      }
    });

    map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(buscad.value);

    const movePacContainer = () => {
      const pacContainer = document.querySelector(".pac-container");
      if (pacContainer && buscad.value) {
        buscad.value.appendChild(pacContainer);
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const pacContainer = document.querySelector(".pac-container");
          if (pacContainer) {
            movePacContainer();
            observer.disconnect();
            break;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    autocomplete.addListener("place_changed", () => {
      infowindow.value.close();
      marker.value.setVisible(false);

      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        toast.error("No hay detalles disponibles para: '" + place.name + "'");
        return;
      }

      if (place.geometry.viewport) {
        map.value.fitBounds(place.geometry.viewport);
      } else {
        map.value.setCenter(place.geometry.location);
        map.value.setZoom(17);
      }

      marker.value.setPosition(place.geometry.location);
      marker.value.setVisible(true);

      const latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      updateLocation(latLng, place.name);
    });

    map.value.addListener("click", (event) => {
      const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      marker.value.setPosition(latLng);
      updateLocation(latLng);
    });
  } catch (error) {
    console.error("Ocurrió un error:", error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style>
#map {
  height: 500px;
  width: 100%;
}

.pac-container {
  position: fixed !important;
  width: 100% !important;
  max-width: 374px;
  z-index: 1000;
  left: 5 !important;
  top: 0 auto !important;
}

.pac-card {
  background-color: #fff;
  border-radius: 2px;
  border: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  margin: 5px;
  padding: 5px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.info-window {
  width: 200px;
  color: black;
}
</style>
