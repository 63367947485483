<template>
      <div class="card mb-4 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
          <h1 class="mb-4">Buscador Analogo</h1>
          <div class="form-group">
            <label for="tipoProducto">Seleccione el tipo de producto:</label>
            <select v-model="selectedTipo" class="form-control" id="tipoProducto">
              <option value="" disabled>Seleccione un tipo</option>
              <option v-for="tipo in tiposProductos" :key="tipo.value" :value="tipo.value">
                {{ tipo.label }}
              </option>
            </select>
          </div>

          <div v-if="selectedTipo === 'vehiculoPasajero'" class="form-group">
            <label for="buscar">Buscar:</label>
            <input 
              v-model="searchTerm" 
              type="text" 
              class="form-control" 
              id="buscar" 
              placeholder="Buscar por cualquier marca o característica...">
          </div>
  
          <div v-if="filteredResults.length > 0 && selectedTipo === 'vehiculoPasajero'">
            <h4 class="mt-4">Resultados:</h4>
            <div class="table-responsive"> 
              <table class="table table-striped table-bordered mt-2">
                <thead class="thead-dark">
                  <tr>
                    <th>LUBRICANTE</th>
                    <th>GRADO VISCOSIDAD</th>
                    <th>GRADO API/ACEA</th>
                    <th>MOBIL</th>
                    <th>SHELL</th>
                    <th>CASTROL</th>
                    <th>HAVOLINE</th>
                    <th>PENZOIL</th>
                    <th>PETRONAS</th>
                    <th>REPSOL</th>
                    <th>TOTAL</th>
                    <th>VALVOLINE</th>
                    <th>MOTUL</th>
                    <th>LIQUI MOLY</th>
                    <th>QUAKER STATE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lubricante in filteredResults" :key="lubricante.id">
                    <td>{{ lubricante.lubricante }}</td>
                    <td>{{ lubricante.viscosidad }}</td>
                    <td>{{ lubricante.api }}</td>
                    <td>{{ lubricante.mobil }}</td>
                    <td>{{ lubricante.shell }}</td>
                    <td>{{ lubricante.castrol }}</td>
                    <td>{{ lubricante.havoline }}</td>
                    <td>{{ lubricante.penzoil }}</td>
                    <td>{{ lubricante.petronas }}</td>
                    <td>{{ lubricante.repsol }}</td>
                    <td>{{ lubricante.total }}</td>
                    <td>{{ lubricante.valvoline }}</td>
                    <td>{{ lubricante.motul }}</td>
                    <td>{{ lubricante.liquiMoly }}</td>
                    <td>{{ lubricante.quakerState }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p v-else-if="selectedTipo === 'vehiculoPasajero'" class="text-muted mt-3">No hay resultados disponibles.</p>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedTipo: '',
        searchTerm: '',
        tiposProductos: [
          { label: 'Vehículo Pasajero', value: 'vehiculoPasajero' },
          { label: 'Servicio Pesado', value: 'servicioPesado' },
          { label: 'Vehículo Pasajero y Servicio Pesado', value: 'vehiculoAmbos' },
          { label: 'Ramo Industria', value: 'ramoIndustria' },
          { label: 'Ramo de Marinos', value: 'ramoMarinos' },
          { label: 'Sector de Grasas', value: 'sectorGrasas' }
        ],
        lubricantes: [
          {
            id: 1,
            lubricante: 'GENESIS SPECIAL',
            viscosidad: '0W-40',
            api: 'SN/CF',
            mobil: 'Mobil 1 FS',
            shell: 'Helix Ultra',
            castrol: 'Edge',
            havoline: '-',
            penzoil: 'Platinum Euro',
            petronas: 'Syntium 7000',
            repsol: 'Elite Cosmos HP',
            total: 'Quartz 9000 energy',
            valvoline: 'SynPower',
            motul: '8100 X-Max',
            liquiMoly: 'SynthOil Energy',
            quakerState: '-',
          },
          {
            id: 2,
            lubricante: 'GENESIS SPECIAL XFE',
            viscosidad: '0W-20',
            api: 'SN-RC',
            mobil: 'Mobil 1',
            shell: 'Helix Ultra',
            castrol: '-',
            havoline: 'ProDS ECO 5',
            penzoil: 'Platinum',
            petronas: '-',
            repsol: '-',
            total: '-',
            valvoline: 'Advanced Full Synthetic',
            motul: '-',
            liquiMoly: '-',
            quakerState: 'Full Synthetic PRO',
          },
          {
            id: 3,
            lubricante: 'GENESIS SPECIAL XFE',
            viscosidad: '5W-30',
            api: 'SN-RC',
            mobil: 'Mobil 1',
            shell: 'Helix Ultra',
            castrol: 'Castrol Edge Dexos 1',
            havoline: 'ProDS ECO 5',
            penzoil: 'Platinum',
            petronas: '-',
            repsol: '-',
            total: 'Quartz 9000 future FGC',
            valvoline: 'Advanced Full Synthetic',
            motul: '-',
            liquiMoly: '-',
            quakerState: 'Full Synthetic PRO',
          }
        ]
      };
    },
    computed: {
      filteredResults() {
        if (!this.searchTerm) {
          return this.lubricantes;
        }
        const searchLower = this.searchTerm.toLowerCase();
        return this.lubricantes.filter(lubricante =>
          Object.values(lubricante).some(value =>
            value.toString().toLowerCase().includes(searchLower)
          )
        );
      }
    }
  };
  </script>
  
  <style >

  </style>
  