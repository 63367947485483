import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/plus-small_4338829.png'
import _imports_1 from '../../assets/minus-small_4338830.png'
import _imports_2 from '../../assets/icons8-cita-recurrente-50.png'
import _imports_3 from '../../assets/movilidad.png'
import _imports_4 from '../../assets/embotellamiento.png'
import _imports_5 from '../../assets/mapa.png'
import _imports_6 from '../../assets/pantalla-completa.png'


const _hoisted_1 = {
  key: 0,
  class: "sidebar"
}
const _hoisted_2 = { class: "search-bar" }
const _hoisted_3 = { class: "tabs" }
const _hoisted_4 = { class: "vehicle-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "map-container" }
const _hoisted_7 = {
  id: "map",
  ref: "mapRef"
}
const _hoisted_8 = { class: "map-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dashboard-container", { 'sidebar-hidden': !$setup.isSidebarVisible }])
  }, [
    ($setup.isSidebarVisible)
      ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
          _cache[29] || (_cache[29] = _createElementVNode("h2", null, "Filtros", -1)),
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchQuery) = $event)),
              placeholder: "Buscar vehículo..."
            }, null, 512), [
              [_vModelText, $setup.searchQuery]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: $setup.selectedTab === 'all' }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.setTab('all')))
            }, " Todos ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: $setup.selectedTab === 'NAENS' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.setTab('NAENS')))
            }, " NAENS ", 2),
            _createElementVNode("button", {
              class: _normalizeClass(["tab-button", { active: $setup.selectedTab === 'SILM' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.setTab('SILM')))
            }, " SILM ", 2)
          ]),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredVehicles, (vehicle) => {
              return (_openBlock(), _createElementBlock("li", {
                key: vehicle.imei,
                onClick: ($event: any) => ($setup.focusOnVehicle(vehicle)),
                class: "vehicle-item"
              }, [
                _cache[11] || (_cache[11] = _createElementVNode("strong", null, "Fecha:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.fecha) + " - ", 1),
                _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Unidad:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.unidad) + " ", 1),
                _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Posición:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.latitud.toFixed(5)) + ", " + _toDisplayString(vehicle.longitud.toFixed(5)) + " ", 1),
                _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Ubicación: :", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.ubicacion) + " ", 1),
                _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                _cache[18] || (_cache[18] = _createElementVNode("strong", null, "imei:", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.imei) + " ", 1),
                _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Velocidad: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.velocidad) + " ", 1),
                _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Placas: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.placas) + " ", 1),
                _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Angulo: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.Angulo) + " ", 1),
                _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                _cache[26] || (_cache[26] = _createElementVNode("strong", null, "ignicion: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.ignicion) + " ", 1),
                _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                _cache[28] || (_cache[28] = _createElementVNode("strong", null, "odometro: ", -1)),
                _createTextVNode(" " + _toDisplayString(vehicle.odometro), 1)
              ], 8, _hoisted_5))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, null, 512),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.zoomIn && $setup.zoomIn(...args)))
        }, _cache[30] || (_cache[30] = [
          _createElementVNode("img", {
            src: _imports_0,
            title: "Zoom In",
            alt: "Zoom In"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.zoomOut && $setup.zoomOut(...args)))
        }, _cache[31] || (_cache[31] = [
          _createElementVNode("img", {
            src: _imports_1,
            title: "Zoom Out",
            alt: "Zoom Out"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($setup.resetView && $setup.resetView(...args)))
        }, _cache[32] || (_cache[32] = [
          _createElementVNode("img", {
            src: _imports_2,
            title: "Restablecer Vista",
            alt: "Restablecer Vista"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($setup.toggleMapType && $setup.toggleMapType(...args)))
        }, _cache[33] || (_cache[33] = [
          _createElementVNode("img", {
            title: "Cambiar vista",
            src: _imports_3,
            alt: "Cambiar Vista"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($setup.toggleTraffic && $setup.toggleTraffic(...args)))
        }, _cache[34] || (_cache[34] = [
          _createElementVNode("img", {
            src: _imports_4,
            title: "Mostrar tráfico",
            alt: "Mostrar/Ocultar Tráfico"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($setup.centerOnUserLocation && $setup.centerOnUserLocation(...args)))
        }, _cache[35] || (_cache[35] = [
          _createElementVNode("img", {
            src: _imports_5,
            title: "Mi Ubicación",
            alt: "Mi Ubicación"
          }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "icon-btn",
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => ($setup.toggleFullscreen && $setup.toggleFullscreen(...args)))
        }, _cache[36] || (_cache[36] = [
          _createElementVNode("img", {
            src: _imports_6,
            title: "Pantalla completa",
            alt: "Pantalla Completa"
          }, null, -1)
        ]))
      ])
    ])
  ], 2))
}