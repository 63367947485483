<template>

  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-15 p-lg-30">
      <h5 class="card-title fw-bold mb-15 mb-md-25">Actualizar usuario</h5>

      <VueLoading :active="isLoading" :is-full-page="true" loader="bars" :text="'Cargando...'" />

      <div v-if="!isLoading">
        <div class="row">
          <div class="col-md-6 pt-10">
            <div class="input-group">
              <span class="input-group-text">Nombre:</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="nombre" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group">
              <span class="input-group-text">Contraseña:</span>
              <input type="password" class="form-control shadow-none fs-md-15 text-black" v-model="clave" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group">
              <span class="input-group-text">Email:</span>
              <input type="email" class="form-control shadow-none fs-md-15 text-black" v-model="email" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group">
              <span class="input-group-text">Teléfono:</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="telefono" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group">
              <span class="input-group-text">Dirección:</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="direccion" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group">
              <span class="input-group-text">RFC:</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" v-model="rfc" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group w-100">
              <label for="basicFormControlInput1" class="form-label fw-medium text-black">
                Almacenes
              </label>
              <MultiSelect v-model="selectedAlmacenes" :options="almacenes" optionLabel="nombre" filter
                placeholder="Seleccione almacenes" :maxSelectedLabels="30" class="w-100" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group w-100">
              <label for="basicFormControlInput1" class="form-label fw-medium text-black">
                Roles
              </label>
              <MultiSelect v-model="selectedRoles" :options="roles" optionLabel="rol" filter
                placeholder="Seleccione el rol" :maxSelectedLabels="1" class="w-100" />
            </div>
          </div>
          <div class="col-md-6 pt-10">
            <div class="input-group w-100">
              <label for="basicFormControlInput1" class="form-label fw-medium text-black">
                Permisos
              </label>
              <MultiSelect v-model="selectedPermisos" :options="permisos" optionLabel="permiso" filter
                placeholder="Seleccione los permisos" :maxSelectedLabels="50" class="w-100" />
            </div>
          </div>

          <div class="col-md-12 pt-10">
            <div class="col-sm-12 col-md-6 pt-4 row justify-content-center">
              <button type="button" @click="actualizarFormulario" class="btn btn-primary text-center"
                :disabled="isSubmitting">

                Actualizar
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import { useToast } from "vue-toastification";
import control from "@/services/apiService";
import 'vue-multiselect/dist/vue-multiselect.css';
import MultiSelect from 'primevue/multiselect';

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  }
});
const nombre = ref("");
const clave = ref("");
const email = ref("");
const isLoading = ref(true);
const telefono = ref("");
const direccion = ref("");
const rfc = ref("");
const almacenes = ref([]);
const roles = ref([]);
const permisos = ref([]);
const selectedAlmacenes = ref([]);
const selectedRoles = ref([]);
const selectedPermisos = ref([]);
const toast = useToast();
const loading = ref(false);

const getAlmacenes = async () => {
  try {
    const response = await new control().ListAlmacenes();
    almacenes.value = response.datos;
  } catch (error) {
    toast.error("Error al cargar los almacenes.");
  }
};

const getRoles = async () => {
  try {
    const response = await new control().ListRoles();
    roles.value = response.datos;
  } catch (error) {
    toast.error("Error al cargar los roles");
  }
};

const getPermisos = async () => {
  try {
    const response = await new control().ListPermisos();
    permisos.value = response.datos;
  } catch (error) {
    toast.error("Error al cargar los permisos");
  }
};

const getInfoUser = async () => {
  try {
    const response = await new control().infoUser({ uuid: props.uuid });

    if (!response.error) {
      nombre.value = response.datos.nombre;
      clave.value = response.datos.clave;
      email.value = response.datos.email;
      telefono.value = response.datos.telefono;
      direccion.value = response.datos.direccion;
      rfc.value = response.datos.rfc;

      selectedAlmacenes.value = almacenes.value.filter(a => response.datos.almacenes.includes(a.uuid));
      selectedRoles.value = roles.value.filter(r => response.datos.roles.includes(r.uuid));
      selectedPermisos.value = permisos.value.filter(p => response.datos.permisos.includes(p.uuid));

    } else {
      toast.error("Error al obtener la información del usuario");
    }
  } catch (error) {
    console.error("Error en getInfoUser:", error);
    toast.error("Ocurrió un error al obtener los datos del usuario");
  }
};

const actualizarFormulario = async () => {
  if (loading.value) return; // Prevenir doble clic

  loading.value = true; // Activar loading

  const datosActualizados = {
    uuid: props.uuid,
    nombre: nombre.value,
    clave: clave.value,
    email: email.value,
    telefono: telefono.value,
    direccion: direccion.value,
    rfc: rfc.value,
    rols: selectedRoles.value.map(r => r.uuid),
    permisos: selectedPermisos.value.map(p => p.uuid),
    almacenes: selectedAlmacenes.value.map(a => a.uuid)
  };

  try {
    const response = await new control().updateUser(datosActualizados);
    if (!response.error) {
      toast.success("Usuario actualizado correctamente");
    } else {
      toast.error("Hubo un problema al actualizar el usuario");
      console.error("Error en la respuesta:", response.errors_low);
    }
  } catch (error) {
    toast.error("Hubo un problema al actualizar el usuario");
  } finally {
    loading.value = false;
  }
};


onMounted(async () => {
  isLoading.value = true;
  await getAlmacenes();
  await getRoles();
  await getPermisos();
  await getInfoUser();
  isLoading.value = false;
});
</script>
