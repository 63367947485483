import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "d-sm-flex align-items-center" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "text-primary" }
const _hoisted_6 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueLoading = _resolveComponent("VueLoading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueLoading, {
        active: _ctx.isLoading,
        "is-full-page": true,
        loader: "bars",
        text: 'Cargando...'
      }, null, 8, ["active"]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
        _createElementVNode("h5", { class: "text-center" }, "Inventario")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", _hoisted_4, [
          _cache[2] || (_cache[2] = _createTextVNode(" Fecha y hora de actualización: ")),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.updateDate), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_InputText, {
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Buscar por SKU o nombre de producto",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_DataTable, {
          value: _ctx.filteredInventory,
          showGridlines: "",
          tableStyle: "min-width: 50rem",
          paginator: "",
          rows: 10,
          rowsPerPageOptions: [5, 10, 20, 50]
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total " + _toDisplayString(_ctx.inventoryData ? _ctx.inventoryData.length : 0) + " Productos. ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "ccodigoproducto",
              header: "Sku",
              style: {"width":"10%"}
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "cnombreproducto",
              header: "Nombre",
              style: {"width":"60%"}
            }),
            _createVNode(_component_Column, {
              sortable: "",
              field: "existencia",
              header: "Cantidad",
              style: {"width":"20%"}
            }),
            _createVNode(_component_Column, {
              header: "Existencia",
              style: {"width":"10%"}
            }, {
              body: _withCtx((slotProps) => [
                _createVNode(_component_Button, {
                  icon: "pi pi-plus-circle",
                  label: "Ver",
                  onClick: ($event: any) => (_ctx.seeDetails(slotProps.data.ccodigoproducto)),
                  severity: "primary",
                  size: "small"
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _createVNode(_component_Dialog, {
      header: "Detalles del Producto",
      visible: _ctx.displayDetailsDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayDetailsDialog) = $event)),
      modal: true,
      closable: true,
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        (_ctx.selectedProductDetails)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.selectedProductDetails, (detail, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("p", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Almacén:", -1)),
                  _createTextVNode(" " + _toDisplayString(detail.CNOMBREALMACEN), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Existencia:", -1)),
                  _createTextVNode(" " + _toDisplayString(detail.existencia), 1)
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1))
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}