<template>
  <div class="row">
    <div ref="buscad" class="col-md-3 m-2 pt-15">
      <div class="card">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Busqueda inteligente"
            v-model="searchText"
            @keyup.enter="handleSearch"
          />
          <span class="input-group-text" @click="handleSearch">
            <i class="flaticon-search"></i>
          </span>
          <span v-if="searchText" class="input-group-text" @click="clearSearch">
            <i class="flaticon-close"></i>
          </span>
        </div>
      </div>
    </div>
    <div ref="vendorselect" class="col-md-2 pt-15">
      <multiselect
        v-model="selectedAgentUuids"
        :options="agents"
        :multiple="true"
        :searchable="true"
        :closeOnSelect="false"
        :hideSelected="false"
        track-by="uuid"
        label="nombre_agente_cq"
        placeholder="Selecciona agentes..."
        selectLabel=""
        deselectLabel=""
        class="bg-white border border-gray-300 rounded-md"
        @update:modelValue="updateSelectedAgents"
      >
        <template #singleLabel="{ option }">
          <div class="flex items-center">
            <span>{{ option.nombre_agente_cq }}</span>
          </div>
        </template>
        <template #tag="{ option, remove }">
          <div class="flex items-center bg-gray-200 rounded p-1 mr-1">
            <span>{{ option.nombre_agente_cq }}</span>
            <button type="button" @click="remove(option)" class="ml-1">
              &times;
            </button>
          </div>
        </template>
      </multiselect>
    </div>
    <div ref="regionSelect" class="col-md-2 pt-15">
      <multiselect
        v-model="selectedRegion"
        :options="regions"
        :searchable="true"
        :closeOnSelect="true"
        :hideSelected="false"
        track-by="uuid"
        label="nombre"
        selectLabel=""
        deselectLabel=""
        placeholder="Selecciona región.."
        class="bg-white border border-gray-300 rounded-md"
        @update:modelValue="updateSelectedRegions"
      >
        <template #singleLabel="{ option }">
          <div class="flex items-center">
            <span>{{ option.nombre }}</span>
          </div>
        </template>
        <template #tag="{ option, remove }">
          <div class="flex items-center bg-gray-200 rounded p-1 mr-1">
            <span>{{ option.nombre }}</span>
            <button type="button" @click="remove(option)" class="ml-1">
              &times;
            </button>
          </div>
        </template>
      </multiselect>
    </div>
    <div ref="serbar" class="col-md-2 pt-15 ml-5">
      <multiselect
        v-model="selectedState"
        :options="states"
        :searchable="true"
        :closeOnSelect="true"
        :hideSelected="false"
        track-by="uuid"
        label="nombre"
        selectLabel=""
        deselectLabel=""
        placeholder="Selecciona estados.."
        class="bg-white border border-gray-300 rounded-md"
        @update:modelValue="updateSelectedStates"
      >
        <template #singleLabel="{ option }">
          <div class="flex items-center">
            <span>{{ option.nombre }}</span>
          </div>
        </template>
        <template #tag="{ option, remove }">
          <div class="flex items-center bg-gray-200 rounded p-1 mr-1">
            <span>{{ option.nombre }}</span>
            <button type="button" @click="remove(option)" class="ml-1">
              &times;
            </button>
          </div>
        </template>
      </multiselect>
    </div>

    <div id="map" style="width: 100%; height: 600px"></div>

    <div class="modal fade" id="basicModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Detalles adicionales</h1>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="activeMarker">
              <p>
                <strong>Uniclave:</strong> {{ activeMarker.get("uniclave") }}
              </p>
              <p><strong>RFC:</strong> {{ activeMarker.get("rfc") }}</p>
              <p>
                <strong>Razón Social:</strong>
                {{ activeMarker.get("razon_social") }}
              </p>
              <p><strong>CP:</strong> {{ activeMarker.get("cp") }}</p>
              <p>
                <strong>Vendedor:</strong> {{ activeMarker.get("vendedor") }}
              </p>
              <p><strong>Estado:</strong> {{ activeMarker.get("estado") }}</p>
              <p>
                <strong>Municipio:</strong> {{ activeMarker.get("municipio") }}
              </p>
              <p><strong>Colonia:</strong> {{ activeMarker.get("colonia") }}</p>
              <p><strong>Calle:</strong> {{ activeMarker.get("calle") }}</p>
              <p>
                <strong>Número Exterior:</strong>
                {{ activeMarker.get("numero_ext") }}
              </p>
              <p>
                <strong>Teléfono:</strong> {{ activeMarker.get("telefono") }}
              </p>
              <p>
                <strong>Correo Electrónico:</strong>
                {{ activeMarker.get("correo_electronico") }}
              </p>
              <p>
                <strong>nombre establecimiento:</strong>
                {{ activeMarker.get("nombre_establecimiento") }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <VueLoading
      :active="isLoading"
      :is-full-page="true"
      loader="bars"
      :text="'Cargando...'"
    />
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from "vue";
import control from "@/services/apiService";
import { useToast } from "vue-toastification";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { agentesItem, statesItem } from "@/interfaces/interfaces";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { Modal } from "bootstrap";

export default {
  name: "MapComponent",
  components: {
    Multiselect,
  },
  setup() {
    const map = ref<google.maps.Map | null>(null);
    const markers = ref<google.maps.Marker[]>([]);
    const mapCenter = ref({ lat: 23.6345, lng: -102.5528 });
    const mapZoom = ref(5);
    const activeInfoWindow = ref<google.maps.InfoWindow | null>(null);
    const locations = ref<any[]>([]);
    const filteredLocations = ref<any[]>([]);
    const isLoading = ref(true);
    const toast = useToast();
    const regionSelect = ref<HTMLElement | null>(null);
    const stateSelect = ref<HTMLElement | null>(null);
    const serbar = ref<HTMLElement | null>(null);
    const buscad = ref<HTMLElement | null>(null);
    const vendorselect = ref<HTMLElement | null>(null);
    const agents = ref<agentesItem[]>([]);
    const selectedAgents = ref<agentesItem[]>([]);
    let markerClusterer: MarkerClusterer | null = null;
    const selectedAgentUuids = ref<agentesItem[]>([]);
    const states = ref<statesItem[]>([]);
    const selectedState = ref<statesItem[]>([]);
    const regions = ref<statesItem[]>([]);
    const selectedRegion = ref<statesItem[]>([]);
    const searchText = ref("");
    const shouldClearMarkers = ref(false);
    const activeMarker = ref<google.maps.Marker | null>(null);
    const bootstrapModal = ref<Modal | null>(null);

    watch(searchText, (newValue) => {
      if (newValue.trim() === "") {
        clearSearch();
      }
    });

    const updateSelectedAgents = (value) => {
      selectedAgentUuids.value = value;
      handleAgentChange();
    };

    const updateSelectedStates = (value) => {
      if (value) {
        selectedState.value = [value];
      } else {
        selectedState.value = [];
      }
      handleStatesChange();
    };

    const closeModal = () => {
      if (bootstrapModal.value) {
        bootstrapModal.value.hide();
      }
    };

    const updateSelectedRegions = (value) => {
      if (value) {
        selectedRegion.value = [value];
      } else {
        selectedRegion.value = [];
      }
      handleRegionsChange();
    };

    // Verificar si un UUID de agente ya está seleccionado
    const isAgentSelected = (uuid) => {
      return selectedAgentUuids.value.includes(uuid);
    };

    // Alternar la selección de un agente por UUID
    const toggleAgent = (uuid) => {
      const isSelected = isAgentSelected(uuid);
      if (isSelected) {
        selectedAgentUuids.value = selectedAgentUuids.value.filter(
          (id) => id !== uuid
        );
      } else {
        selectedAgentUuids.value.push(uuid);
      }
      handleAgentChange();
    };

    async function initMap() {
      const { Map } = (await google.maps.importLibrary(
        "maps"
      )) as google.maps.MapsLibrary;

      map.value = new Map(document.getElementById("map") as HTMLElement, {
        zoom: mapZoom.value,
        center: mapCenter.value,
        mapId: "f8d56006232c1d87",
      });

      const modalElement = document.getElementById("basicModal") as HTMLElement;
      map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(
        modalElement
      );

      const BuscardorRes = buscad.value as HTMLElement;
      map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(
        BuscardorRes
      );

      const selectElementVendor = vendorselect.value as HTMLElement;
      map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(
        selectElementVendor
      );

      const SearchBa = serbar.value as HTMLElement;
      map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(SearchBa);

      const selectElement = regionSelect.value as HTMLElement;
      map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(
        selectElement
      );

      updateMarkers();
    }

    const toggleInfoWindow = (marker: google.maps.Marker) => {
      if (activeInfoWindow.value) {
        activeInfoWindow.value.close();
      }
      const infoWindow = new google.maps.InfoWindow({
        content: getInfoWindowContent(marker),
      });
      infoWindow.open(map.value, marker);
      activeInfoWindow.value = infoWindow;
      activeMarker.value = marker;

      google.maps.event.addListenerOnce(infoWindow, "domready", () => {
        document.getElementById("verMasBtn")?.addEventListener("click", () => {
          if (bootstrapModal.value) {
            bootstrapModal.value.show();
          }
        });
      });
    };

    const getInfoWindowContent = (marker: google.maps.Marker) => {
      return `
        <div class="info-window">
          <p><strong>Uniclave:</strong> ${marker.get("uniclave") || "N/A"}</p>
          <p><strong>RFC:</strong> ${marker.get("rfc") || "N/A"}</p>
          <p><strong>Razon social:</strong> ${
            marker.get("razon_social") || "N/A"
          }</p>
          <p><strong>CP:</strong> ${marker.get("cp") || "N/A"}</p>
          <p><strong>Vendedor:</strong> ${marker.get("vendedor") || "N/A"}</p>
          <button id="verMasBtn" class="btn btn-primary">Ver más</button>
        </div>
      `;
    };

    const getMarkerIcon = (color) => {
      return `http://maps.google.com/mapfiles/ms/icons/${color}`;
    };

    const clearSearch = () => {
      searchText.value = "";
      shouldClearMarkers.value = true;
      locations.value = [];
      markers.value = [];
      filteredLocations.value = [];
      updateMap();
    };

    const handleSearch = async () => {
      if (searchText.value.trim() === "") {
        clearSearch();
        toast.warning("Por favor, ingrese un texto para buscar.");
        return;
      }

      try {
        isLoading.value = true;
        const response = await new control().serchIntelligent(searchText.value);
        shouldClearMarkers.value = true;
        locations.value = [];
        markers.value = [];
        filteredLocations.value = [];

        if (response.datos && response.datos !== "") {
          locations.value = response.datos.map((item) => {
            // Validamos si 'item.informacion' es un array y tiene al menos un elemento
            const info =
              Array.isArray(item.informacion) && item.informacion.length > 0
                ? item.informacion[0]
                : {}; // Si no, asignamos un objeto vacío para evitar errores

            return {
              lat: parseFloat(item.latitud),
              lng: parseFloat(item.longitud),
              rfc: item.rfc,
              razonSocial: item.razon_social,
              calle: item.calle1
                ? `${item.calle1} ${item.numero_ext || ""}`
                : "N/A",
              cp: item.cp,
              estado: item.estado,
              correo_electronico: item.correo_electronico,
              nombre_establecimiento: item.nombre_establecimiento,
              vendedor: item.vendedor,
              telefono: item.telefono,
              uniclave: item.uniclave,
              municipio: item.municipio || "N/A", // Uso seguro de 'info'
              colonia: item.colonia || "N/A",
              telefonoContacto: item.telefonoContacto || "N/A",
              correoElectronico: item.correoElectronico || "N/A",
              numero_ext: item.numero_ext || "N/A",
              numero_int: item.numero_int || "N/A",
              color: item.color,
            };
          });

          filteredLocations.value = locations.value;
          updateMap();
          toast.success(`Se encontraron ${locations.value.length} resultados.`);
        } else {
          toast.warning("No se encontraron resultados para la búsqueda.");
        }
      } catch (error) {
        console.error("Error al realizar la búsqueda inteligente:", error);
        toast.error(
          "Error al realizar la búsqueda. Por favor, intente de nuevo."
        );
      } finally {
        isLoading.value = false;
      }
    };

    const handlePointsChange = async (
      selectedData,
      listPointsFunction,
      warningMessage,
      status
    ) => {
      const uuids = selectedData.value.map((item) => item.uuid);
      if (uuids && uuids.length > 0) {
        try {
          isLoading.value = true;
          const response = await new control()[listPointsFunction](uuids);
          locations.value = [];

          shouldClearMarkers.value = true;
          markers.value = [];

          if (response.datos && response.datos !== "") {
            if (status === 1) {
              const { lat, lng } = selectedData.value[0].center;
              mapCenter.value = { lat: parseFloat(lat), lng: parseFloat(lng) };
              mapZoom.value = parseFloat(selectedData.value[0].zoom);
            }

            locations.value = response.datos
              .map((item) => {
                const info =
                  Array.isArray(item.informacion) && item.informacion.length > 0
                    ? item.informacion[0]
                    : {};
                const lat = parseFloat(item.latitud);
                const lng = parseFloat(item.longitud);

                // Verificamos si la latitud y longitud son válidas
                if (isNaN(lat) || isNaN(lng)) {
                  return null; // Si no es válido, retornamos null
                }

                return {
                  lat: lat,
                  lng: lng,
                  rfc: item.rfc,
                  razonSocial: item.razon_social,
                  calle: item.calle1
                    ? `${item.calle1} ${item.numero_ext || ""}`
                    : "N/A",
                  cp: item.cp,
                  estado: item.estado,
                  correo_electronico: item.correo_electronico,
                  nombre_establecimiento: item.nombre_establecimiento,
                  vendedor: item.vendedor,
                  telefono: item.telefono,
                  uniclave: item.uniclave,
                  municipio: item.municipio || "N/A", 
                  colonia: item.colonia || "N/A",
                  telefonoContacto: item.telefonoContacto || "N/A",
                  correoElectronico: item.correoElectronico || "N/A",
                  numero_ext: item.numero_ext || "N/A",
                  numero_int: item.numero_int || "N/A",
                  color: item.color,
                };
              })
              .filter((location) => location !== null); 

            toast.success(
              `Se encontraron ${locations.value.length} resultados.`
            );

            filteredLocations.value = locations.value;
            updateMap();
          } else {
            toast.warning(warningMessage);
          }
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      } else {
        shouldClearMarkers.value = true;
        locations.value = [];
        markers.value = [];
        filteredLocations.value = [];
        updateMap();
      }
    };

    const handleStatesChange = () => {
      handlePointsChange(
        selectedState,
        "listStatesPoints",
        "Este estado no contiene datos",
        1
      );
    };

    const handleRegionsChange = () => {
      handlePointsChange(
        selectedRegion,
        "listRegionPoints",
        "Esta región no contiene datos",
        1
      );
    };

    const clearMarkers = async () => {
      if (!shouldClearMarkers.value) return;
      // Eliminar todos los marcadores del mapa
      markers.value.forEach((marker) => {
        if (marker && typeof marker.setMap === "function") {
          marker.setMap(null); // Eliminar marcador del mapa
        }
      });
      markers.value = [];

      // Limpiar el MarkerClusterer si existe
      if (
        markerClusterer &&
        typeof markerClusterer.clearMarkers === "function"
      ) {
        markerClusterer.clearMarkers(); // Limpiar los clusters
        markerClusterer = null;
      }
    };

    const updateMap = () => {
      if (map.value) {
        map.value.setCenter(mapCenter.value);
        map.value.setZoom(mapZoom.value);
        updateMarkers();
      }
    };

    const updateMarkers = () => {
      if (map.value) {
        clearMarkers();

        // Añadir los marcadores filtrados
        const newMarkers = filteredLocations.value
          .map((location, i) => {
            if (isNaN(location.lat) || isNaN(location.lng)) {
              console.error("coordenadas inválidas:", location);
              return null;
            }

            const label = {
              text: location.uniclave,
              color: "black",
              fontSize: "12px",
              fontWeight: "bold",
            };

            const marker = new google.maps.Marker({
              position: { lat: location.lat, lng: location.lng },
              label: label,
              icon: getMarkerIcon(location.color),
              map: map.value,
            });

            // Agregar datos adicionales al marcador
            marker.set("rfc", location.rfc);
            marker.set("razon_social", location.razonSocial);
            marker.set("calle", location.calle);
            marker.set("cp", location.cp);
            marker.set("estado", location.estado);
            marker.set("correo_electronico", location.correo_electronico);
            marker.set("nombre_establecimiento", location.nombre_establecimiento);
            marker.set("telefono", location.telefono);
            marker.set("uniclave", location.uniclave);
            marker.set("vendedor", location.vendedor);
            marker.set("municipio", location.municipio);
            marker.set("colonia", location.colonia);
            marker.set("telefonoContacto", location.telefonoContacto);
            marker.set("correoElectronico", location.correoElectronico);
            marker.set("numero_ext", location.numero_ext);
            marker.set("color", location.color);

            marker.addListener("click", () => toggleInfoWindow(marker));

            markers.value.push(marker);

            return marker;
          })
          .filter((marker) => marker !== null); // Filtrar marcadores nulos

        // Si tienes más de un marcador, usa el MarkerClusterer
        if (newMarkers.length > 0) {
          markerClusterer = new MarkerClusterer({
            map: map.value,
            markers: newMarkers.filter(
              (marker): marker is google.maps.Marker => marker !== null
            ),
          });
        }
      }
    };

    const getAgentList = async () => {
      try {
        const response = await new control().agentList();
        agents.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los puntos:", error);
        toast.error(
          "Error al obtener los puntos. Por favor, intente de nuevo."
        );
      } finally {
        isLoading.value = false;
      }
    };

    const handleAgentChange = async () => {
      handlePointsChange(
        selectedAgentUuids,
        "listAgentPoints",
        "Este agente no contiene datos",
        2
      );
    };

    const getStateList = async () => {
      try {
        const response = await new control().stateList();
        states.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los puntos:", error);
        toast.error(
          "Error al obtener los puntos. Por favor, intente de nuevo."
        );
      } finally {
        isLoading.value = false;
      }
    };

    const getRegionList = async () => {
      try {
        const response = await new control().regionList();
        regions.value = response.datos;
      } catch (error) {
        console.error("Error al obtener los puntos:", error);
        toast.error(
          "Error al obtener los puntos. Por favor, intente de nuevo."
        );
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      try {
        isLoading.value = true;
        await initMap();
        await getAgentList();
        await getStateList();
        await getRegionList();

        const modalElement = document.getElementById("basicModal");
        if (modalElement) {
          bootstrapModal.value = new Modal(modalElement, {
            keyboard: false,
            backdrop: "static",
          });
        }
      } catch (error) {
        console.error("Error al inicializar el mapa:", error);
        toast.error(
          "Ocurrió un error al cargar la información, intente de nuevo."
        );
      } finally {
        isLoading.value = false;
      }
    });

    return {
      selectedRegion,
      selectedState,
      regions,
      states,
      handleStatesChange,
      isLoading,
      regionSelect,
      stateSelect,
      serbar,
      buscad,
      vendorselect,
      agents,
      selectedAgents,
      handleAgentChange,
      toggleAgent,
      isAgentSelected,
      updateSelectedAgents,
      selectedAgentUuids,
      updateSelectedStates,
      updateSelectedRegions,
      handleRegionsChange,
      searchText,
      handleSearch,
      clearSearch,
      activeMarker,
      bootstrapModal,
      closeModal,
    };
  },
};
</script>

<style>
.info-window {
  width: 200px;
  color: black;
}

.searchear {
  margin-top: 30px;
  padding: 30px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.search-input {
  width: 300px;
  padding: 5px;
}

.card-reduced-padding {
  padding: -13rem !important;
}

.modal-backdrop {
  display: none;
}

.modal-content{
  width: 75% !important;
}

</style>
